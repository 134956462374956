.homeNavigation {
  grid-row: 1/ span 2;
  grid-column: 2;
  justify-self: center;
  align-self: start;
  margin-top: 20px;
}

.homeNavList {
  margin-top: 0;
  list-style: none;
  height: min-content;
  width: min-content;
}

.vertNav {
  margin-left: -20px;
  padding: 15px 15px 15px 30px;
  text-align: center;
}

.homeNavList li {
  background-color: white;
  margin-bottom: 20px;
  width: 300px;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #3B55E6;
  padding: 15px 0 15px 30px;
  border-radius: 25px;
}

.homeNavList li:hover {
  cursor: pointer;
}

.homeNavListArrow {
  float: right;
  margin-right: 30px;
}

.navigationHr {
  border: 1px solid blue;
}

.miLogoLogin {
  margin: -6px auto 0 auto;
  width: 210px;
}

.navBar {
  grid-column: 1 /span 2;
  grid-row: 1;
  margin: 0;
  width: 100%;
  display: flex;
  box-shadow: 0px 3px 10px rgb(206, 204, 204);
  z-index: 100;
}

.navBarList {
  list-style: none;
  height: 100%;
  width: 90%;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

.navBarList li {
  width: 100%;
  height: 100%;
  font-size: 25px;
  display: flex;
  align-items: center;
}

.clLink {
  color: #3B55E6;
  cursor: pointer;
}

.leftTopNav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  margin-left: 7px;
}


#miLogoLi {
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.miLogoNav {
  width: 150px;
  margin-left: 5px;
}

.rightNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 75%;
}

.navViewBtn {
  cursor: pointer;
  font-size: 21px;
  font-weight: normal;
  height: min-content;
  border: none;
  background-color: white;
  outline: none;
}

.navViewBtnActive {
  color: black;
  text-decoration: underline;
  text-decoration-color: #3B55E6;
  cursor: pointer;
  font-size: 21px;
  font-weight: normal;
  height: min-content;
  border: none;
  background-color: white;
  outline: none;
}

.navArrowDown {
  margin-left: 10px;
}

.userOptionsDropDown {
  justify-self: center;
  align-self: center;
  height: min-content;
  text-align: center;
  font-size: 14px;
}

.userOptions {
  z-index: 100;
  position: absolute;
  width: 220px;
  border: 1px solid black;
  border-radius: 2px;
  background-color: white;
  font-size: 16px;
}

.userOptions ul {
  list-style-type: none;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

.userOptions ul li {
  padding: 5px 0;
  font-size: 14px;
}

.userOptions ul li:hover {
  background-color: #e9e9e9;
}

.userOptionsArrow {
  margin-left: 7px;
}

#logoutDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.logoutBtn {
  width: 120px;
  font-size: 16px;
  height: 40px;
  padding: 0 20px;
  align-self: center;
  border: 2px solid #3B55E6;
  background-color: white;
}

@media (max-width: 1000px) {
  .navBarList {
    margin: 0 2vw;
  }

  .navBarList li {
    font-size: 20px;
  }
}