textarea {
  border:solid;
  width: 99%;
}


.clContainer {
    display: flex;
    /* flex: 1; */
    flex-flow: column wrap;
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
    padding: 20px 16px 20px 20px;
    border:solid;
    height: 50%;
    overflow: auto;
  }

.clCard {
    /* Add shadows to create the "card" effect */
    display: flex;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px 5px 5px 5px;
    padding:  5px 5px 5px 5px;
    margin:  5px 5px 5px 5px;
    max-width: 30%;
    min-width: 20%;

  }
  
  /* On mouse-over, add a deeper shadow */
  .clCard:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transform: scale(1.1);
  }

  .clMsg {
      height: 20px;
  }
