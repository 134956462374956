.container {
    display: flex;
    width: 99%;
    height: 95%;
    background-color: white;
}

.shortContainer {
    display: flex;
    width: 99%;
}

.left {
    display: flex;
    flex-direction: column;
    min-width: 50vw;
    max-width: 70vw;
}

.right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.insert {
    height: 50%;
    margin: 10px;
    overflow: auto;
    border: solid;
}

.compareButtonsContainer {
    display: flex;
    width: 340px;
    justify-content: space-between;
    margin: 0 2px;
}

.compareButton {
    width: 60px;
    font-size: 12px;
}

.compareButtonLong {
    width: 150px;
    font-size: 12px;
}