.userListOptionsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 3px;
}
.accountRegButton {
    margin: 5px auto;
    width: 200px;
}

.accountUpdating {
    display: flex;
    flex-direction: column;
    align-content: center;
    background-color: white;
    margin: auto;
    padding: 15px;
    width: 500px;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 2px;
    border: 1px solid black;
    z-index: 100;
    /* transform: translateX(-50%); */
}
.stackedButtons{
    
    display: flex;
    flex-direction: column;
    align-content: center;
}
.accountUpdatingNavigation {
    grid-row: 1 / span 2;
    grid-column: 2;
    align-self: start;
    justify-self: center;
}

.accountUpdatingFormContainer {
    grid-column: 1;
    grid-row: 1 / span 2;
    background-color: white;
    margin-top: 20px;
    border-radius: 10px;
    align-self: center;
    justify-self: center;
    height: min-content;
    width: 70%;
    min-width: 300px;
    padding-bottom: 10px;
}
.CenterButton{
    margin: 0 auto;
    width: 180px;
}


.accountUpdatingHeading {
    margin-top: 0;
    text-align: center;
    font-size: 29px;
}

.accountUpdatingForm {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid #000;
    padding: 10px;
}

.accountUpdatingForm label {
    margin-top: 5px;
    font-family: Helvetica;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 2px;
    width: 100%;
}

.account-properties{
    display:block;
    border: solid 1px #000;
    padding: 2px;
}

.account-properties h3{
        font-family: Helvetica;
        font-weight: 300;
        font-size: 18px;
        letter-spacing: 2px;
        width: 100%;
    
}
.accountUpdatingForm input {
    width: 95%;
    border: none;
    background: #E3E8F0;
    border-radius: 3px;
    font-size: 16px;
    padding: 8px 5px;
}

.acctSeparation {
    margin-bottom: 3px;
}

.accountUpdatingSubmitBtn, 
.accountRegClear,
.accountRegClose{
    margin: 10px auto;
    width: 180px;
    height: 30px;
    border-radius: 3px;
    color: white;
    font-size: 16px;
    font-family: Helvetica;
    background-color: #3B55E6;
    letter-spacing: 1px;
    border: none;
    cursor: pointer;
}

.accountRegCancel {
    margin: 10px auto;
    text-align: center;
    width: 180px;
    line-height: 30px;
    height: 25px;   
    border-radius: 3px;
    font-size: 18px;
    font-family: Helvetica;
    letter-spacing: 1px;
    cursor: pointer;
    border: solid 1px #000;
    background-color: white;
    color: black !important;
}

.accountUpdatingResult {
    display: grid;
    justify-self: start;
    align-self: center;
    background-color: white;
    border-radius: 3px;
    width: 100%;
}

.accountUpdatingResponseMessage {
    align-self: center;
    justify-self: center;
    text-align: center;
    font-family: Helvetica;
    font-size: 14px;
    border: 2px solid #3B55E6;
    padding: 3px;
    width: 98%;
}

.userUpdateButton {
    margin: 5px auto 0 auto;
    width: 200px;
}
/* .showHideProperties{
    overflow: hidden; 
 }
.showHideProperties div{
    width: 50%;
    float: left;
} */

.grid-2{
    display: grid;
    grid-template-columns: 15% 1fr;
    grid-gap: 2px;
    padding: 2px;

}
.grid-4{
    display: grid;
    grid-template-columns: 15% 1fr 10% 10%;
    grid-gap: 2px;
    padding: 2px;

}
#propertyInputs div label{
    line-height: 30px;

}
#propertyInputs div button{
    background-color: #3B55E6;
    color: #fff;
    border-radius: 4px;
    border: none;
    font-weight: bold;
    font-size: 20px;
    padding: 0;
    width: 35px;
    height: 35px;
    line-height: 30px;  

}

.property-pill{
    background-color: #3B55E6;
    color: #fff;
    border-radius: 5px;
    position: relative;
    float: left;
    height: 20px;
    padding: 5px;
}
.property-pill span {
	border: none;
	color: #fff;
	margin-left: 3px;
	padding: 0 2px;
    pointer-events: none;
}
.property-container{
    border: 2px solid #bebfc3;
    /* box-shadow: inset 1px 1px 3px #999; */
    overflow-y: auto;
    min-height: 100px;
    max-height: 150px;
}
.property-container ul{
    list-style-type: none;
    display: block;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content:flex-start

}
.property-container ul li{
    margin: 5px;
    float: left;

}
.clError {
	color: rgb(212, 30, 30);
	font-weight: bold;
	z-index: 1000;
	word-wrap: break-word;
	width: 490px;
    margin-top: 20px;
    margin-left:10px;
}