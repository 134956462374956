.dataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 30%; left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 10vh;
    width: 400px;
    padding: 20px;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 3px 5px rgb(192, 192, 192);
    overflow: hidden;  
}

.dataNameDisplay {
    margin-bottom: 20px;
}

.dataName {
    font-size: 25px;
    margin-bottom: 20px;
}

.inputContainer {
    margin-bottom: 10px;
    width: 375px;
}

.inputField {
    font-size: 16px;
    line-height: 20px;
    width: 98%;
}

.dataRespMessage {
    text-align: center;
    background-color: white;
    border-radius: 3px;
    border: 2px solid #3B55E6;
    padding: 3px;
    width: 98%;
}

.Ok {
    color: green;
}

.Error {
    color: red;
}

.dataButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90px;
    margin-top: 20px;
    justify-content: space-between;
}