.dataOptionsContainer {
    /* grid-column: 1 / span 3; */
    grid-row: 1;
    height: 50px;
    width: 100%;
    background-color:#4A4A4B;
    display: flex;
    align-items: center;
}

.dataOptions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid white; */
    /* width: 100%; */
}

.dataOptionsList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style-type: none;
}

.dataOptionsList li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 35px;
    background-color: #58585A;
    box-shadow: 3px 3px 5px rgb(51, 50, 50);

    color: #E9E9EF;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    margin-left: 3px;
    cursor: pointer;
}

.greyLeftArrow {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

/* .dataOptions h2 {
    margin: 0 0 0 5px;
}

.options {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.options button {
    background: rgb(56,73,110);
    background: linear-gradient(0deg, rgba(56,73,110,1) 0%, rgba(37,86,229,1) 100%);
    box-shadow: 0 3px 5px rgb(158, 158, 158, 1);
    border: none;
    color: white;
    width: 180px;
    height: 35px;
    border-radius: 1px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: bold;
    margin-right: 5px;
}

.editIcon {
    width: 16px;
    margin-right: 10px;
}

.folderDeleteIcon {
    width: 16px;
    margin-right: 10px;
} */