.userListContainer {
    display: grid;
    grid-template-rows: 50px min-content min-content;
    height: 80vh;
    margin: 0 auto;
    background-color: white;
    padding: 20px;
    box-shadow: 0 3px 5px rgb(158, 158, 158, 0.4);
}

.usersLoadingSpinner {
    margin-top: -20%;
}

.listOfUsers {
    margin: 20px auto;
    width: 80%;
}

.userListOptionsContainer {
    display: flex;
    justify-content: flex-end;
    margin: 0 3px 10px 3px;
}

.userTableContainer {
    overflow: auto;
    width: 100%;
    max-height: 73vh;
    margin-top: 10px;
}

.userTableContainer thead tr th, 
.userTableContainer thead tr {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #EDEDED;
    line-height: 2em;
    height: 2.3em;
}

.userTableContainer,
.userTableContainer td {
  box-shadow: inset 2px 2px rgb(190, 191, 195, 1);
}

.userTableContainer th {
  box-shadow: inset 2px 2px rgb(190, 191, 195, 1), 1px 1px rgb(190, 191, 195, 1);
}

.userTableContainer tbody {
    margin-top: 10px;
}

.trOn {
    background: rgb(56,73,110);
    background: linear-gradient(0deg, rgba(56,73,110,1) 0%, rgba(37,86,229,1) 100%);
    box-shadow: 0 3px 5px rgb(158, 158, 158, 1);
    border: none;
    color: white;
    width: 150px;
    height: 40px;
    border-radius: 1px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: bold;
}

.trOff {
    background-color:#EDEEF2;
    box-shadow: 0 3px 5px rgb(158, 158, 158, 0.4);
    border: none;
    color: #A9A9A9;
    width: 150px;
    height: 40px;
    border-radius: 1px;
    letter-spacing: 1px;
    font-size: 15px;
}

.narrowButton {
    width: 150px;
}

.listOfUsersTable {
    grid-row: 3;
    text-align: center;
    font-size: 20px;
    width: calc(100% - 2px);
    margin: 0 auto;
    border-collapse: collapse;
}

.listOfUsersTable th {
    color: #707070;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    z-index: 100;
    height: 30px;
}

.checkboxColumn {
    width: 20px;
    border-bottom: 1px solid rgb(190, 191, 195, 0.7);

}

.tableSortArrow {
    height: 10px;
    width: 15px;
    float: right;
    margin-top: 9px;
    margin-right: 4px;
}

.listOfUsersTable .cell {
    text-align: left;
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid rgb(190, 191, 195, 0.7);
    max-width: 100px;
    min-width: 60px;
    width: 100px;
    height: 40px;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.userListTR {
    color: #707070;
}

.listOfUsersTable a {
    color: black;
}

.listOfUsersTable tr:nth-child(even) {background: #EDEDED}

.listOfUsersTable #selected {
    background-color: #c8f0fa;
}

@media (max-width: 1100px) {
    .trOn, 
    .trOff {
        width: 125px;
        font-size: 13px;
    }
}