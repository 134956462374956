.accountRegistry {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    position: absolute;
    margin: auto;
    top: 0; right: 0; bottom: 0; left: 0;
    padding: 15px;
    width: 400px;
    height: fit-content;
    border-radius: 2px;
    border: 1px solid black;
    z-index: 100;
}

.accountRegistryNavigation {
    grid-row: 1 / span 2;
    grid-column: 2;
    align-self: start;
    justify-self: center;
}

.accountRegistryHeading {
    margin-top: 0;
    text-align: center;
    font-size: 29px;
}

.accountRegistryForm {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 80%;
    margin: 0 auto;
}

.accountRegistryForm label {
    font-family: Helvetica;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 2px;
    width: 100%;
}

.accountRegistryForm input {
    margin-bottom: 10px;
    width: 95%;
    border: none;
    background: #E3E8F0;
    border-radius: 3px;
    font-size: 16px;
    padding: 8px 5px;
}

.acctSeparation {
    margin-bottom: 3px;
}

.accountRegistryResult {
    display: grid;
    justify-self: start;
    align-self: center;
    background-color: white;
    border-radius: 3px;
    width: 100%;
}

.accountRegistryResponseMessage {
    align-self: center;
    justify-self: center;
    text-align: center;
    font-family: Helvetica;
    font-size: 14px;
    border: 2px solid #3B55E6;
    padding: 3px;
    width: 98%;
}

.accountRegButton {
    margin: 5px auto;
    width: 200px;
}