hr{
    margin: 5px;
}

.assignAccountUserContainer {
    grid-column: 1;
    grid-row: 2;
    width: 98%;
    min-width: 210px;
    max-width: 400px;
    height: 90%;
    align-self: start;
}

.assignDetails {
    background: white;
    width: 90%;
    height: 95%;
    margin: 20px auto; 
    box-shadow: 0 3px 5px rgb(158, 158, 158, 0.4);
    display: flex;
    flex-direction: column;
}

.assignDetails h2 {
    margin: 5px auto;
    color: #2556E5;
}

.selectedUserName {
    color: #2556E5;
    font-size: 23px;
    text-align: center;
    padding: 5px 0;
}

.selectedUserInfo {
    text-align: center;
    padding: 5px 0;
    margin: 10px;
    color: #707070
}

.currentAssignments {
    height: 60vh;  
}

.currentAssignments h2 {
    color: #2556E5;
    font-size: 23px;
    text-align: center;
    padding: 5px;
}

.assignedTableContainer {
    overflow-y: auto;
    height: 50vh;
    margin-top: 10px;   
}

.assignedDetailsList {
    margin: 5px 5px 5px 20px;
    padding: 3px;
    max-height: calc(50vh - 40px);
}

.assignedDetailsList li {
    text-overflow: ellipsis;
    width: 100%;
    color: #707070;
    padding: 5px 3px;    
}

.assignedDetailsList li:nth-child(2n-2) {
    background-color: #EDEEF2;
}

.editAssignToggleBtn {
    background: rgb(56,73,110);
    background: linear-gradient(0deg, rgba(56,73,110,1) 0%, rgba(37,86,229,1) 100%);
    box-shadow: 0 3px 5px rgb(158, 158, 158, 1);
    border: none;
    color: white;
    width: 180px;
    height: 35px;
    border-radius: 1px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: bold;
    margin: 5px auto;
}

@media (max-height: 810px) {
    .assignedTableContainer {
        height: 45vh;
    }
}