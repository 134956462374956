.account-tags{
    margin-top: 10px;
    display: block;
    border: solid 1px #000;
    padding: 2px;
}

.account-tags h3{
    font-family: Helvetica;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 2px;
    width: 100%;   
}

.grid-2{
    display: grid;
    grid-template-columns: 15% 1fr;
    grid-gap: 2px;
    padding: 2px;
}

.grid-5{
    display: grid;
    grid-template-columns: 15% 1fr 10% 10% 10%;
    grid-gap: 2px;
    padding: 2px;
}

#tagInputs div label{
    line-height: 30px;
}

.tag-button:focus {
    border: solid 3px #fff;
}
    .tag-button {
    width: 35px;
    height: 35px;
    line-height: 30px;
}

.tag-pill{
    background-color: #3B55E6;
    color: #fff;
    border-radius: 5px;
    position: relative;
    float: left;
    padding: 5px;
}

.tag-pill span {
	border: none;
	color: #fff;
	margin-left: 3px;
	padding: 0 2px;
    pointer-events: none;
}

.tag-container{
    border: 2px solid #bebfc3;
    overflow-y: auto;
    min-height: 100px;
    max-height: 150px;
}

.tag-container ul{
    list-style-type: none;
    display: block;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content:flex-start

}

.tag-container ul li{
    margin: 5px;
    float: left;
}