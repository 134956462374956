.deleteDataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 30%; left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 20px;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 3px 5px rgb(192, 192, 192);
    overflow: hidden;   
}

.deleteDataNameDisplay {
    display: flex;
    margin-bottom: 10px;
}

.deleteMessage {
    display: flex;
    margin-bottom: 25px;
}

.deleteDataButtonsContainer {
    display: flex;
    width: 350px;
    justify-content: space-between;
}