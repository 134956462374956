.uploadDisplay {
    /* grid-column: 1 / span 3;
    grid-row: 1 / span 2; */
    position: absolute;
    width: 100%;
    padding: 20px;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 3px 5px rgb(192, 192, 192);
    overflow: hidden;
    border-top: 1px solid #707070;
}

.testUpload {
    position: absolute;
    margin: 0 auto;
    padding-top: 10px;
    top: 10px; right: 0; bottom: 0; left: 0;
    /* padding: 20px 15px; */
    width: 600px;
    height: 250px;
    border: 1px solid black;
    z-index: 1000;
    background-color: white;
    padding: 20px;
}

.testUpload p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dataFileForm {
    height: 100%;
    display: flex;
    justify-content: center;
}

.alignContainer {
    display: flex;
    flex-direction: column;
}

.selectParent {
    display: flex;
    flex-wrap: wrap;
    width: min-content;
}

.selectParentLabel {
    flex-basis: 100%;
}

.parentSelectMenu {
    color: #2556E5;
    font-size: 14px;
    appearance: none;
    background-image: url('../../../../images/blueBoxArrow.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 30px;
    padding-left: 3px;
}


.fileUploadDiv {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    /* align-items: center; */
    /* justify-content: space-evenly; */
}

.selectFileDiv {
    margin-left: 100px;
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid blue; */
    align-items: center;
    width: 100%;
}

.selectFileLabel {
    font-size: 16px;
    flex-basis: 100%;
}

.uploadFileSelect, #file {
    white-space: nowrap;
    width: 240px;
    color: #2556E5;
    padding: 5px 5px 5px 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #707070;
    font-size: 14px;
    display: inline-block;
    height: 16px;
    /* height: 30px; */
}

.uploadError {
    color: red;
    font-size: 13px;
    margin-left: 17px;
    width: 150px;
    height: 17px;
}

.previewSection {
    /* grid-row: 3; */
    width: 90%;
    height: 320px;
    margin-top: 10px;
    display: inline-block;
    display: flex;
    flex-direction: column;
}

.previewOuterBorder {
    margin-top: 10px;
    padding: 7px;
    height: 200px;
    border-color: #707070;
    border-style: solid;
    border-width: 1px 1px 0px 1px;
    width: 800px;
}

.previewListContainer {
    border: 1px solid #707070;
    height: 175px;
    overflow: scroll;
}

.previewList {
    list-style: none;
    width: max-content;
    margin: 5px;
    padding: 5px;
}

.previewList li {
    font-size: 14px;
    cursor: pointer;
    color: #707070;
    padding: 5px;
}

.blankRow {
    height: 10px;
}
.previewHeaderSelection {
    border-color: #707070;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    padding: 7px;
    width: 800px;
}

.previewHeaderSelection p {
    margin: 0;
}

.currentHeader {
    margin-top: 0;
    border: 1px solid #707070;
    padding: 2px;
    min-height: 23px;
    display: flex;
    align-items: center;
    color: #2556E5;
}

.currentHeader p {
    width: 98%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    padding: 0;
    margin:0;
}

.parentOptionsContainer {
    grid-row: 4;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.parentSelectMenu {
    width: 250px;
    height: 28px;
    border-radius: 0;
    border: 1px solid #707070;
}

.nameFileDiv {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.nameFileDiv label {
    font-size: 16px;
}

#fileName {
    margin-top: 7px;
    width: 250px;
    padding: 5px 5px 5px 3px;
    border: 1px solid #707070;
    font-size: 14px;
    color: #2556E5;
    /* height: 28px; */
    /* height: 14.5px */
}

#fileName {
    width: 250px;
}

.uploadDataFileBtns {
    margin-right: 35px;
    align-self: flex-end;
    width: 400px;
    display: flex;
    justify-content: flex-end;
}

.uploadDataFileSubmitBtn {
    border: none;
    margin-top: 10px;
    margin-left: 20px;
    width: 175px;
    height: 35px;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    background: rgb(56,73,110);
    background: linear-gradient(0deg, rgba(56,73,110,1) 0%, rgba(37,86,229,1) 100%);
}

.closeUploadDataFile {
    border: none;
    margin-top: 10px;
    margin-left: 20px;
    width: 175px;
    height: 35px;
    color: #8A8A8A;
    font-weight: bold;
    letter-spacing: 1px;
}

