.passwordResetDiv {
    background-color: white;
    border: 1px solid black;
    border-radius: 3px;
    position: relative;
    left: -50%;
    width: 350px;
    display: flex;
    flex-direction: column;
}

.passwordResetDiv p {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    text-align: center;
}

.passwordResetDiv button {
    width: 150px;
    margin: 5px auto;
    background-color: #3B55E6;
    border-radius: 3px;
    border: none;
    color: white;
    padding: 5px;
}