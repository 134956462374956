.viewExpirySetCard {
    overflow-y: auto;
    position: absolute;
    margin: auto;
    top: 0; right: 0; bottom: 0; left: 0;
    padding: 5px 15px;
    width: 500px;
    height: 250px;
    min-height: 250px;
    border: 1px solid black;
    border-radius: 3px;
    background-color: white;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.expUserName {
    font-size: 22px;
    margin-top: -3px;
}


/* .expDate {
    grid-row: 1;
    grid-column: 2;

    font-size: 15px;
} */

.expirySetForm {
    height: 100%;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* align-items: center; */
}

/* .expirySetForm label {
    align-self: center;
}
*/

.expirySetForm input {
    align-self: center;
    height: 20px;
    width: 200px;
    text-align: center;
    font-size: 16px;
} 
.expUserDetails {
    display: grid;
    height: min-content;
}

.expUserDate {
    justify-self: start;
    margin: 0;
}

.expirySetForm button, .viewExpirySetCard button {
    width: 180px;
    height: 30px;
    margin-top: 5px;
    border-radius: 3px;
    /* color: white; */
    font-size: 16px;
    font-family: Helvetica;
    /* background-color: #3B55E6; */
    letter-spacing: 1px;
    /* border: none; */
    cursor: pointer;
}

.expFormSetBtn {
    background-color: #3B55E6;
    border: none;
    color: white;
    margin-left: 20px;

}

.expCloseBtn {
    background-color: white;
    border: 1px solid black;
}

.expResponseDiv {
    height: 50px;
    font-size: 14px;
    width: 80%;
    padding: 0;
    margin: 0 auto;
}

.expResponseDiv p {
    padding: 0;
    margin: 0;
}