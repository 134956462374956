.cancelBtn {
    border: none;
    width: 175px;
    height: 35px;
    color: #8A8A8A;
    font-weight: bold;
    letter-spacing: 1px;
}

.confirmBtn {
    border: none;
    /* margin-top: 10px; */
    width: 175px;
    height: 35px;
    color: white;
    letter-spacing: 1px;
    font-weight: bold;
    background: rgb(56,73,110);
    background: linear-gradient(0deg, rgba(56,73,110,1) 0%, rgba(37,86,229,1) 100%);
}