.appHeading {
    /* grid-row: 1; */
    /* grid-column: 1; */
    /* padding: 20px; */
    /* margin: 20px; */
    padding: 5px;
    color: #2556E5;
    font-weight: bold;
    /* align-self: center; */
    /* font-size: 35px; */
}
.appInfo {
    /* grid-row: 1; */
    /* grid-column: 1; */
    padding: 5px;
    /* margin: 20px; */
    color: black;
    font-weight: bold;
    /* align-self: center; */
    /* font-size: 35px; */
}
.appWarning {
    /* grid-row: 1; */
    /* grid-column: 1; */
    padding: 5px;
    /* margin: 20px; */
    color: #da4848;
    font-weight: bold;
    /* align-self: center; */
    /* font-size: 35px; */
}

.appTable {
    /* grid-row: 3; */
    font-size: 20px;
    /* width: 100%; */
    /* min-width: 750px; */
    margin: 10px auto;
    border-collapse: collapse;
    border: 2px solid rgb(190, 191, 195, 0.7);
    text-align: center;
}

.appTable th {
    color: #707070;
    font-size: 18px;
    border: 1px solid rgb(190, 191, 195, 1);
    font-weight: 600;
    cursor: pointer;
    z-index: 100;
    height: 30px;
}

.appTable td {
    border: 1px solid rgb(190, 191, 195, 0.7);
}

.appTable .cell {
    /* padding-right: 50px; */
    font-size: 15px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid rgb(190, 191, 195, 0.7);
    max-width: 100px;
    min-width: 60px;
    width: 100px;
    height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}