.listOfAccounts {
    width: 80%;
    max-height: 100%;
    margin: 0 auto;
}

.accountsLoadingSpinner {
    margin-top: -20%;
}

.accountListContainer {
    display: grid;
    grid-template-rows: 1fr;
    margin: 20px auto;
    min-width: 610px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 3px 5px rgb(158, 158, 158, 0.4);
}

.accountTableContainer {
    max-height: 100%;
    margin-top: 10px;
    width: 100%;
}

.listOfAccountsHeading {
    grid-row: 1;
    grid-column: 1;
    padding: 0;
    margin:0;
    color: #2556E5;
    font-weight: bold;
    align-self: center;
    font-size: 35px;
}

.accountListOptionsContainer {
    display: flex;
    justify-content: space-between;
    margin: 0 3px 10px 3px;
}

.accountOptions {
    float: right;
}

#openAccountBtn {
    justify-self: flex-start;
    margin-left: 2px;
}

.listOfAccountsTable {
    grid-row: 3;
    font-size: 20px;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid rgb(190, 191, 195, 0.7);
    border-top: none;
    text-align: center;
}

.listOfAccountsTable th {
    color: #707070;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    z-index: 100;
    height: 30px;
}

.tableFixHead {
    overflow: auto;
    max-height: 65vh;
}

.tableFixHead thead tr th, 
.tableFixHead thead tr {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #EDEDED;
    line-height: 2em;
    height: 2.3em;
}

.tableFixHead,
.tableFixHead td {
  box-shadow: inset 2px 2px rgb(190, 191, 195, 1);
}

.tableFixHead th {
  box-shadow: inset 2px 2px rgb(190, 191, 195, 1), 1px 1px rgb(190, 191, 195, 1);
}

.tableFixHead tbody {
    margin-top: 10px;
}

.accountTableSortArrow {
    height: 10px;
    width: 15px;
    float: right;
    margin-top: 9px;
    margin-right: 20px;
}

.listOfAccountsTable .cell {
    font-size: 15px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid rgb(190, 191, 195, 0.7);
    max-width: 100px;
    min-width: 40px;
    width: 100px;
    height: 40px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.listOfAccountsTable td {
    border: 1px solid rgb(190, 191, 195, 0.7);
}

.accountListTR {
    color:#707070;
}

.listOfAccountsTable a {
    color: black;
}

.listOfAccountsTable tr:nth-child(even) {background: #EDEDED}

.listOfAccountsTable #selected {
    background-color: #c8f0fa;
}

.matchingAccount {
    background-color: orange;
}

@media (max-width: 1000px) {
    .listOfAccounts {
        margin: 0 2vw;
    }
 
    .trOn, .trOff, #openAccountBtn {
        min-width: 100px;
        width: 5vw;
    }

    #openAccountBtn {
        margin-right: auto;
    }
}
