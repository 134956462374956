.workInAccountContainer {
    overflow-y: hidden;
    overflow-x: hidden;
    position: absolute;
    margin: 0;
    /* padding-top: 10px; */
    top: 80px; right: 0; bottom: 0; left: 0;
    /* padding: 20px 15px; */
    width: 100%;
    height: 90%;
    min-height: 250px;
    border-radius: 3px;
    background-color: #EDEEF2;
    z-index: 1000;   
}

.workAccountsLoading {
    margin: 100px auto;
    text-align: center;
}

.workInAccount{
    width: 100%;
    height: 80vmin;
    display: grid;
    /* grid-template-columns: 300px 2fr 275px; */
    grid-template-rows: min-content 1fr;
}

.workInAccountColumns {
    grid-row: 2;
    width: 100%;
    height: 75vmin;
    display: flex;
}

@media (min-width: 90vw) {
    .workInAccountContainer {
      overflow-x: scroll;
    }
  }

 .fileStructure {
    background-color: white;
    box-shadow: 0 3px 5px rgb(158, 158, 158, 0.4);
    width: 100%;
    height: 98%;
    margin: 10px auto;
    overflow: auto;
}

.react-checkbox-tree li {
    margin: 10px;
}

#tsFolderClosed, #tsFolderOpened {
    width: 18px;
}