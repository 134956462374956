/*
POINT VERGE BLUE: 
#3B55E6
*/

/* background: rgb(56,73,110);
background: linear-gradient(0deg, rgba(56,73,110,1) 0%, rgba(37,86,229,1) 100%); */

html {
  overflow-y: hidden;
  overflow-x: hidden;
}

* {
  font-family: arial;
}

h1 {
  font-weight: normal;
}

a {
  text-decoration: none;
}

a:visited {
  color: #3B55E6;
}

.App {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  min-height: 100vh;
  max-width: 100vw;
}

.miLogo {
  grid-row: 1;
  grid-column: 1/span 2;
  align-self: center;
  justify-self: center;
  width: 210px;
}

.loginLink {
  font-size: 25px;
  float: right;
  margin-right: 200px;
}

.clBody {
  justify-content: center;
  display: flex;
  height: 100%;
  background-color: #EDEEF2;
}

.loadingHeading {
  grid-column: 1 / span 2;
  justify-self: center;
  margin-top: 30vmin;
}

.userPermissionStatus {
  grid-row: 1;
  grid-column: 2;
  justify-self: end;
  margin-right: 200px;
}

.loading {
  font-size: 30px;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 1000ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

.clError {
  color: rgb(212, 30, 30);
  font-weight: bold;
  z-index: 1000;
  word-wrap:break-word;
  

}

/* Tooltip container */
.clToolTip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.clToolTip .clToolTipText {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
 
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.clToolTip:hover .clToolTipText {
  visibility: visible;
}

.clVersion {
  bottom: 50%;
  right: 50%;
  width: 200px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

.pvLogoHome {
  width: 100px;
  position: fixed;
  bottom: 1%;
  right: 1%;
}

.lmLogoHome {
  /* width: 100px; */
  height: 40px;
  position: fixed;
  bottom: 1%;
  left: 1%;
}
