.moveDataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 30%; left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 20px;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 3px 5px rgb(192, 192, 192);
    overflow: hidden;   
}

.moveDataNameDisplay {
    display: flex;
    margin-bottom: 10px;
    width: 350px;
}

.moveMessage {
    display: flex;
    margin-bottom: 25px;
    width: 350px;
}

.moveDataName {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.moveDataLabelKey {
    font-size: 25px;
}

.moveDataLabelValue {
    font-size: 20px;
}

.moveDataCurrentParent {
    display: flex;
    align-items:baseline;
    margin-top: 20px;
    width: 350px;
}

.moveDataCurrParKey {
    font-size: 18px;
}

.moveDataCurrParValue {
    font-size: 18px;
    margin-left: 15px;
}

.moveDropDownContainer {
    display: flex;
    justify-content: left;
    width: 350px;
    margin: 10px 0px 30px 0px;
}

.moveDropDown {
    width: 125px;
    margin-left: 15px;
}

.moveDataButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90px;
    justify-content: space-between;
}