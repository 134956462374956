.loginContainer {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    justify-self: center;
    align-self: center;
    margin-top: -20vh;
    border-radius: 3px;
    /* min-height: 260px; */
    /* height: 25vh; */
    height: 260px;
    width: 400px;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 10px rgb(206, 204, 204);

}

.loginForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
}

.loginEmailDiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.loginForm label {
    color: #3B55E6;
    font-family: Helvetica;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 2px;
    align-self: flex-start;
}

.loginPasswordDiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.loginForm input {
    min-width: 200px;
    width: 20vw;
    border: none;
    background: #E3E8F0;
    border-radius: 3px;
    font-size: 16px;
    height: 20px;
    padding: 5px;
}

.loginSubmitBtn {
    margin-top: 5px;
    width: 180px;
    height: 30px;
    border: none;
    border-radius: 3px;
    background: rgb(56,73,110);
    background: linear-gradient(0deg, rgba(56,73,110,1) 0%, rgba(37,86,229,1) 100%);
    color: white;
    font-size: 16px;
    letter-spacing: 1px;
    justify-self: center;
}

.loginSubmitBtn:active {
    color: blue;
    background-color: white;
}

.errorMessage {
    text-align: center;
    width: 210px;
    font-size: 14px;
}

.invalidEmailDiv {
    height: 35px;
    width: 400px;
    text-align: center;
}

.invalidEmailPass {
    color: red;
    font-size: 14px;
    text-align: center;
    display: inline;
    padding: 0;

}

.forgotPasswordDiv {
    margin: 10px 0 0 0;
    height: 20px;
    width: 200px;
    text-align: center;
}

.forgotPassword {
    color: blue;
    cursor: pointer;
    display: inline;
    font-size: 14px;
}

.welcomeHeading {
    margin: 0 5px;
    color: #3B55E6;
}

.pvLogo {
    width: 10vw;
    max-width: 200px;
    min-width: 100px;
    grid-column: 2;
    grid-row: 1/ span 2;
    justify-self: end;
    align-self: end;
    margin-right: 10px;
}