.clButtonOn {
    transition: all .5s ease;
    background: rgb(56,73,110);
    background: linear-gradient(0deg, rgba(56,73,110,1) 0%, rgba(37,86,229,1) 100%);
    box-shadow: 0 3px 5px rgb(158, 158, 158, 1);
    border: none;
    color: white;
    width: 150px;
    height: 40px;
    border-radius: 1px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: bold;
}

.clButtonOn:hover {
    opacity: .8;
    cursor: pointer;
}

.clButtonOff {
    transition: all .5s ease;
    background-color:#EDEEF2;
    box-shadow: 0 3px 5px rgb(158, 158, 158, 0.4);
    border: none;
    color: #A9A9A9;
    width: 150px;
    height: 40px;
    border-radius: 1px;
    letter-spacing: 1px;
    font-size: 15px;
}

.accountOptions .clButtonOn, 
.accountOptions .clButtonOff,
.userButtonOptions .clButtonOn,
.userButtonOptions .clButtonOff {
    margin-left: 15px;
}

@media (max-width: 900px) {
    .clButtonOff,
    .clButtonOn {
        font-size: 13px;
        width: 125px;
    }
}