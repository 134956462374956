.deleteAccountContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
    position: absolute;
    margin: 0;
    top: 80px; right: 0; bottom: 0; left: 0;
    width: 100%;
    height: 90%;
    min-height: 250px;
    border-radius: 3px;
    background-color: #EDEEF2;
    z-index: 1000;
    font-size: 20px;   
}

.accountNameDisplay {
    display: flex;
    margin-bottom: 10px;
}

.deleteMessage {
    display: flex;
    margin-bottom: 25px;
}

.deleteAccountBtnsContainer {
    display: flex;
    width: 350px;
    justify-content: space-between;
}

.button {
    padding: 5px 20px;
}