.viewAssignCard {
    overflow-y: hidden;
    overflow-x: hidden;
    position: absolute;
    margin: 0;
    padding-top: 10px;
    top: 90px; right: 0; bottom: 0; left: 0;
    width: 100%;
    height: 90%;
    min-height: 250px;
    border-radius: 3px;
    background-color: #EDEEF2;
    z-index: 80;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: min-content 1fr;
}

.assignClose {
    grid-row: 1;
    grid-column: 1;
    width: min-content;
    justify-self: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.assignClose button {
    background: rgb(56,73,110);
    background: linear-gradient(0deg, rgba(56,73,110,1) 0%, rgba(37,86,229,1) 100%);
    box-shadow: 0 3px 5px rgb(158, 158, 158, 1);
    border: none;
    color: white;
    font-weight: bold;
    font-size: 18px;
    width: 220px;
    height: 40px;
    border-radius: 1px;
    letter-spacing: 1px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.whiteArrowLeft {
    width: 20px;
    height: 20px;
}

.assignMenu {
    grid-column: 2;
    grid-row: 1;
    width: 98%;
    align-self: start;
    display: flex;
    justify-content: space-between;
}

.assignSearchQueryDiv {
    display: inline-block;
    width: 40%;
    border: 1px solid #707070;
    display: flex;
    align-items: center;
}

.assignSearchBar {
    width: 85%;
    height: 100%;
    border: none;
    padding: 0 5px 0 20px;
    background-color: #EDEEF2;
    color: #7C7F8B;
    font-size: 16px;
    outline: none;
}

.assignSearchBar ::placeholder {
    opacity: 1;
}
::-moz-placeholder {
    opacity: 1;
}

.assignSearchBtn {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    height: 100%;
    width: 15%;
}

.searchIcon {
    width: 20px;
}

.assignPaddingDiv {
    grid-column: 2;
    grid-row: 2;
    width: 98%;
    height: 85%;
    margin-top: 20px;
    align-self: start;
    justify-self: start;
    background-color: white;
    padding: 0;
    box-shadow: 0 3px 5px rgb(158, 158, 158, 0.4); 
}

.assignTable {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
    max-height: 65vh;
}

.assignTable .userTableContainer .tableFixHead {
    max-height: 60vh;
}

.assignAccountRoleForm {
    margin-left: 5px;
    width: 65%;
    display: flex;
    justify-content: space-between;
}

.assignAccountRoleForm select {
    width: 68%;
    background: none;
    padding: 0 5px 0 20px;
    font-size: 16px;
    background-color: #EDEEF2;
    appearance: none;
    background: url('../../../../../images/Arrow.png') no-repeat right;
    background-position:
    calc(100% - 21px) calc(1em + 1px),
    calc(100% - 16px) calc(1em + 1px),
    100% 0;
}

.assignAccountSelectEnable {
    border: 1px solid #707070;
    color: #7C7F8B;

}

.assignAccountSelectDisable {
    color: #BEBFC3;
    border: 1px solid rgb(175, 175, 175);
}

#radioPermissionDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.radioSpan {
    box-shadow: 0 3px 5px rgb(158, 158, 158, 1);
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radioPermissionEnable {
    color: #7C7F8B;
}

.radioPermissionDisable {
    color: #BEBFC3;
}

.radioPermissionDisable,
.radioPermissionEnable {
    display: flex;
    width: 100%;
}

#assignAccountRoleSubmit {
    font-size: 19px;
}

.confirmBox {
    overflow-y: auto;
    position: absolute;
    margin: auto;
    top: -200px; right: 0; bottom: 0; left: 0;
    padding: 20px 15px;
    width: 600px;
    height: 200px;
    min-height: 250px;
    border-radius: 3px;
    background-color: white;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    box-shadow: 0 3px 5px rgba(102, 99, 99, 0.4);

}

#assignConfirmBox p{
    font-weight: normal;
    line-height: 30px;
}

.confirmBox p {
    color: #707070;
    font-family: Arial;
    font-weight: bold;
    margin-bottom: 40px;
}

.confirmBoxUserName{
    font-weight: bold;
    color: #2556E5;
}

.confirmBoxBtnDiv {
    display: flex;
    width: 100%;
    justify-content: center;
}

.confirmBoxCancelBtn {
    background:rgb(191, 191, 194);
    box-shadow: 0 3px 5px rgb(158, 158, 158, 1);
    border: none;
    color: white;
    width: 180px;
    height: 35px;
    border-radius: 1px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: bold;
}

.confirmBoxAssignBtn {
    background: rgb(56,73,110);
    background: linear-gradient(0deg, rgba(56,73,110,1) 0%, rgba(37,86,229,1) 100%);
    box-shadow: 0 3px 5px rgb(158, 158, 158, 1);
    border: none;
    color: white;
    width: 180px;
    height: 35px;
    border-radius: 1px;
    letter-spacing: 1px;
    font-size: 15px;
    margin-left: 50px;
    font-weight: bold;
}

.assignAccountAfterSubmit {
    margin: auto;
    text-align: center;
}

.assignAccountGranted {
    font-weight: bold;
    color: #2556E5;
}

.assignAccountFailed {
    font-weight: bold;
    color: rgb(226, 30, 30);
}

.confirmBoxCloseBtn {
    background: rgb(56,73,110);
    background: linear-gradient(0deg, rgba(56,73,110,1) 0%, rgba(37,86,229,1) 100%);
    box-shadow: 0 3px 5px rgb(158, 158, 158, 1);
    border: none;
    color: white;
    width: 180px;
    height: 35px;
    border-radius: 1px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: bold;
}

.editAssignMenu {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.removeAcctBtn {
    background: rgb(141,56,82);
    background: linear-gradient(0deg, rgba(141,56,82,1) 0%, rgba(155,15,15,1) 100%);
    border: none;
    color: white;
    font-size: 15px;
    font-weight: bold;
    width: 220px;
    height: 40px;
    box-shadow: 0 3px 5px rgb(158, 158, 158, 1);
}

#removeAcctBtn {
    font-size: 19px;
    letter-spacing: 1px;
}

.removeAcctSubmit {
    background: rgb(141,56,82);
    background: linear-gradient(0deg, rgba(141,56,82,1) 0%, rgba(155,15,15,1) 100%);
    box-shadow: 0 3px 5px rgb(158, 158, 158, 1);
    border: none;
    color: white;
    width: 180px;
    height: 35px;
    border-radius: 1px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: bold;
    margin-left: 50px;
}

@media (max-width: 1100px) {
    .assignTable {
        padding: 0;
    }
}